import { endpoints } from "../api/endpoints";
import { postRequest } from "../api/middleware";
import { tagsOperations } from "../api/operations";
import { getAppData } from "./app-select-helpers";

export const fetchTagList = ({ onSuccess }) => {
  postRequest({
    operation: tagsOperations.list,
    endpoint: endpoints.tags,
    data: {},
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (error) => {
      console.warn(error);
    },
  });
};

export function setPlausiblePageView() {
  var canonicalMeta = window.location.origin + "/" + window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
  var url = canonicalMeta ? url + canonicalMeta : window.location.href;

  plausible("pageview", { u: canonicalMeta + window.location.search });
}

export const addTag = ({ setTagList, newTag }) => {
  postRequest({
    operation: tagsOperations.createTag,
    endpoint: endpoints.tags,
    data: { tagname: newTag, tagslug: `${newTag}${Math.floor(Math.random() * 100)}` },
    callback: () => {
      fetchTagList({ onSuccess: setTagList });
    },
    errorCallback: (err) => {},
  });
};

export const addTag2 = ({ setTagList, newTag, onSuccess }) => {
  postRequest({
    operation: tagsOperations.createTag,
    endpoint: endpoints.tags,
    data: { tagname: newTag, tagslug: `${newTag}${Math.floor(Math.random() * 100)}` },
    callback: (response) => {
      fetchTagList({ onSuccess: setTagList });
      onSuccess(response);
    },
    errorCallback: (err) => {},
  });
};

/*
export const notificationSettingsTag = ({ tagSlug, onSuccess }) => {
    if (tagSlug) {
        postRequest({
            operation: tagsOperations.notificationDetails,
            endpoint: endpoints.tags,
            data: { tagslug: tagSlug },
            callback: (response) => {
                onSuccess(response.response);
            },
            errorCallback: (err) => {
            },
        });
    }
};

export const notificationSettingsTag2 = ({ tagSlug, onSuccess }) => {
    postRequest({
        operation: tagsOperations.exportDetails,
        endpoint: endpoints.tags,
        data: { tagslug: tagSlug },
        callback: (response) => {
            onSuccess(response.response);
        },
        errorCallback: (err) => {
        },
    });
};

export const notificationSetSettingsTag = ({ tagSlug, notificationSettings, onSuccess, onError }) => {
    postRequest({
        operation: tagsOperations.notificationSetDetails,
        endpoint: endpoints.tags,
        data: { tagslug: tagSlug, notificationSettings: notificationSettings },
        callback: (response) => {
            onSuccess();
        },
        errorCallback: (err) => {
            onError(err);
        },
    });
};
*/

export const exportSettingsTag = ({ tagSlug, onSuccess }) => {
  postRequest({
    operation: tagsOperations.exportDetails,
    endpoint: endpoints.tags,
    data: { tagslug: tagSlug },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {},
  });
};

export const exportSetSettingsTag = ({ tagSlug, exportSettings, onSuccess, onError }) => {
  postRequest({
    operation: tagsOperations.exportSetDetails,
    endpoint: endpoints.tags,
    data: { tagslug: tagSlug, exportSettings: exportSettings },
    callback: (response) => {
      onSuccess();
    },
    errorCallback: (err) => {
      onError(err);
    },
  });
};

export const deleteTag = ({ tagSlug, onSuccess, onError }) => {
  postRequest({
    operation: tagsOperations.deleteTag,
    endpoint: endpoints.tags,
    data: { tagslug: tagSlug },
    callback: () => {
      onSuccess();
    },
    errorCallback: (err) => {
      onError(err);
    },
  });
};

export const setTagSetting = ({ tagSlug, tagSetting, tagSettingValue, onSuccess }) => {
  postRequest({
    operation: tagsOperations.setTagSetting,
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      setting: tagSetting,
      value: tagSettingValue,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const getTagSetting = ({ tagSlug, tagSetting, onSuccess }) => {
  postRequest({
    operation: tagsOperations.getTagSetting,
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      setting: tagSetting,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const addChannelNotification = ({ tagSlug, notificationChannel, onSuccess }) => {
  postRequest({
    operation: "notification/add",
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      notificationChannel: notificationChannel,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const testChannelNotification = ({ tagSlug, notificationChannelId, onSuccess }) => {
  postRequest({
    operation: "notification/testmessage",
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      notificationChannelId: notificationChannelId,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const deleteChannelNotification = ({ tagSlug, notificationChannelId, onSuccess }) => {
  postRequest({
    operation: "notification/delete",
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      notificationChannelId: notificationChannelId,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const updateChannelNotification = ({ tagSlug, notificationChannelId, notificationChannelData, onSuccess }) => {
  postRequest({
    operation: "notification/update",
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      notificationChannelId: notificationChannelId,
      notificationChannelData: notificationChannelData,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const editTag = ({ tagSlug, newTagName, onSuccess }) => {
  postRequest({
    operation: tagsOperations.editTag,
    endpoint: endpoints.tags,
    data: {
      tagslug: tagSlug,
      tagname: newTagName,
    },
    callback: (response) => {
      onSuccess(response.response);
    },
    errorCallback: (err) => {
      console.warn(err);
    },
  });
};

export const fetchAppList = ({ tagSlug, onSuccess }) => {
  postRequest({
    operation: tagsOperations.detail,
    endpoint: endpoints.tags,
    data: { tagslug: tagSlug },
    callback: (response) => {
      const userAppIds = response.response.apps.myapps;
      onSuccess(getAppData(userAppIds, response?.response?.apps?.metadata));
    },
    errorCallback: (error) => {
      console.warn(error);
    },
  });
};

export const getTagObjectFromSlug = (slug, list) => {
  return list.find((tag) => tag.slug === slug);
};
