import { Button, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../library/hooks/context/GlobalContext";
import styles from "./LimitReachedModal.module.css";
import { set } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function LimitModal() {
  const [visible, setVisible] = useState(false);
  const { getGlobalValue, setGlobalValue } = useContext(GlobalContext);
  const [limitReachedMessage, setLimitReachedMessage] = useState("");

  useEffect(() => {
    if (getGlobalValue("limitReachedMessage") && getGlobalValue("limitReachedMessage") != "") {
      setVisible(true);
      setLimitReachedMessage(getGlobalValue("limitReachedMessage"));
    }
  }, [getGlobalValue("limitReachedMessage")]);

  const handleClose = () => {
    setVisible(false);
    setGlobalValue("limitReachedMessage", "");
  };

  const goToPricing = () => {
    window.location.href = "/pricing"; // Replace with your actual pricing page URL
  };

  return (
    <>
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: "#faad14", marginRight: 8, fontSize: 20 }} />
            Keyword Limit Reached
          </span>
        }
        open={visible}
        onCancel={handleClose}
        style={{ top: 20 }}
        footer={[
          <Button key="pricing" type="primary" onClick={goToPricing} style={{ backgroundColor: "#ff4d4f", borderColor: "#ff4d4f" }}>
            Upgrade Now
          </Button>,
        ]}
        bodyStyle={{
          padding: "20px 24px",
          textAlign: "center",
          fontSize: "16px",
          lineHeight: "1.5",
        }}
      >
        <p>You have reached the maximum number of keywords allowed for your current plan.</p>
        <p>To add more keywords, please upgrade your plan.</p>
      </Modal>
    </>
  );
}
