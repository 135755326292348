import _axios from "axios";
import { sprintf } from "sprintf-js";
import { endpoints } from "../api/endpoints";
import { fileUploadRequest, postRequest } from "../api/middleware";
import { tagsOperations, keywordOperations } from "../api/operations";
import { flags } from "./flagImports";

export const addKeywordToProject = (e) => {};

export const getColumnNameNoWhiteSpace = (col) => col.caption.replace(/\s+/g, "");

export const sortCols = (a, b) => {
  return parseInt(b) < parseInt(a) ? -1 : 1;
};

export const sortScoreCols = (a, b, column) => {
  let curr;
  let next;

  if (column.caption === "Search Score") {
    curr = !b ? 0 : b;
    next = !a ? 0 : a;
  } else if (column.caption === "Rank") {
    curr = !a ? 9999 : a;
    next = !b ? 9999 : b;
  } else if (column.caption === "Diff") {
    curr = a == undefined ? -999 : a;
    next = b == undefined ? -999 : b;
  }

  return sortCols(next, curr);
};

export const sortDiffCols = (a, b) => {
  const curr = a === null ? -9999 : a;
  const next = b === null ? -9999 : b;
  return sortCols(curr, next);
};

const trafficModalHandler = (data) => {};

const rankingsModalHandler = (data) => {
  // sendDataHistoryChart(data.column.storeid, 'iphone', data.key, '1');
};

const totalsModalHandler = (data) => {
  // sendDataRank(showapp, data.column.storeid, data.data.Keyword, 'iPhone', 'iphone', 'iOS');
};

const performanceModalHandler = (data) => {
  // sendDataRankStore(showapp, 150, data.data.Keyword, 'iPhone', 'iphone', 'iOS');
};

export const onIconClick = (e, info, type, modalRef = null) => {
  switch (type) {
    case "Search Score":
      trafficModalHandler(info);
      break;
    case "Rank":
      rankingsModalHandler(info);
      break;
    case "Total":
      totalsModalHandler(info);
      break;
    case "Performance":
      performanceModalHandler(info);
      break;
    default:
      break;
  }
};

export const getKeywordsByMarket = (data, market) => {
  //console.log("data", data, market);
  return data.find((store) => store.market === market)?.countries;
};

const getCountryKeywordCount = (countryCode, data) => {
  const country = data.find((countryData) => countryData.country === countryCode);
  return country?.keywords?.length;
};

const getFlag = (countryCode) => {
  return flags[countryCode];
};

export const configureCountrySelectOptions = (countries, keywordData) => {
  let userCountryCodes = [];
  //console.log("keywordData", keywordData);
  if (keywordData != null && keywordData.length) {
    userCountryCodes = keywordData.map((obj) => obj.country);
  } else {
    //return;
  }
  //console.log("userCountryCodes", userCountryCodes);
  let allCountries = {
    userCountries: [],
    otherCountries: [],
  };
  countries.forEach((country) => {
    const flag = getFlag(country.code);
    //const keywordCount = getCountryKeywordCount(country.code, keywordData);
    //if (userCountryCodes.includes(country.code) && keywordCount) {
    if (userCountryCodes.includes(country.code)) {
      allCountries.userCountries.push({
        ...country,
        keywords: 1,
        src: flag,
      });
    } else {
      allCountries.otherCountries.push({
        ...country,
        src: flag,
      });
    }
  });

  return allCountries;
};

export const createKeywordsFormatForDb = (market, countries, keywords, groups = []) => {
  return [
    {
      countries: countries.map((country) => {
        return {
          country: country,
          keywords: keywords.map((keyword) => {
            return {
              group: groups,
              text: keyword,
            };
          }),
        };
      }),
      market: market,
    },
  ];
};

export const createDeletedKeywordsArray = (market, country, keywordIds) => {
  return [
    {
      countries: [
        {
          country: country,
          keywords: keywordIds.map((id) => {
            return { id: id.toString() };
          }),
        },
      ],
      market: market,
    },
  ];
};

export const getCountryKeywordData = (data, countryCode) => {
  if (!data) {
    return;
  }
  return data.find((countryObj) => countryObj.country === countryCode)?.keywords;
};

export const deleteKeyword = ({ tagSlug, market, country, keywordIds, onSuccess, onError }) => {
  const keywordsArr = createDeletedKeywordsArray(market, country, keywordIds);
  const requestData = {
    tagslug: tagSlug,
    keywords: keywordsArr,
  };
  postRequest({
    operation: tagsOperations.deleteKeywords,
    endpoint: endpoints.tags,
    data: requestData,
    callback: (response) => onSuccess(response),
    errorCallback: (error) => onError(error),
  });
};

export const deleteKeywordAll = ({ tagSlug, market, country, onSuccess, onError }) => {
  const requestData = {
    tagslug: tagSlug,
    market: market,
    country: country,
  };
  postRequest({
    operation: tagsOperations.deleteAllKeywords,
    endpoint: endpoints.tags,
    data: requestData,
    callback: (response) => onSuccess(response),
    errorCallback: (error) => onError(error),
  });
};

export const editKeywordGroups = ({ keywordIds, tagSlug, market, country, groups, onSuccess }) => {
  const requestData = {
    tagslug: tagSlug,
    keywords: [
      {
        countries: [
          {
            country: country,
            keywords: keywordIds.map((id) => {
              return {
                id: id,
                group: groups,
              };
            }),
          },
        ],
        market: market,
      },
    ],
  };
  postRequest({
    operation: tagsOperations.setKeywordGroups,
    endpoint: endpoints.tags,
    data: requestData,
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => console.warn(error),
  });
};

export const keywordResultsRanking = ({ keywordid, countryid, market, device, onSuccess }) => {
  const postData = {
    keywordid: keywordid,
    countryid: countryid,
    device: device,
    market: market,
  };

  postRequest({
    operation: keywordOperations.keywordResultsRanking,
    endpoint: endpoints.keyword,
    data: postData,
    callback: (response) => onSuccess(response),
    errorCallback: (error) => console.warn(error),
  });
};

export const keywordTrafficScoreRanking = ({ keywordid, countryid, mstart, mend, market, showImpressions, onSuccess }) => {
  const postData = {
    keywordids: keywordid,
    countryids: [countryid],
    startdate: mstart,
    enddate: mend,
    market: market,
    showImpressions: showImpressions,
  };

  postRequest({
    operation: keywordOperations.trafficscoreHistory,
    endpoint: endpoints.keyword,
    data: postData,
    callback: (response) => onSuccess(response),
    errorCallback: (error) => console.warn(error),
  });
};

export const keywordHistoryRanking = ({ keywordid, countryid, mstart, mend, market, appid, device, onSuccess }) => {
  const postData = {
    keywordids: keywordid,
    countryids: [countryid],
    appids: [appid],
    device: device,
    startdate: mstart,
    enddate: mend,
    market: market,
  };

  postRequest({
    operation: keywordOperations.keywordHistory,
    endpoint: endpoints.keyword,
    data: postData,
    callback: (response) => onSuccess(response),
    errorCallback: (error) => console.warn(error),
  });
};

export const addKeywordGroupToTag = ({ tagSlug, group, onSuccess }) => {
  const postData = {
    tagslug: tagSlug,
    groups: [group],
  };
  postRequest({
    operation: tagsOperations.addKeywordGroup,
    endpoint: endpoints.tags,
    data: postData,
    callback: (response) => onSuccess(response.response.filter((group) => group).sort()),
    errorCallback: (error) => console.warn(error),
  });
};

export const addKeywordsToTag = ({ tagSlug, market, country, device = "", keywords, groups, onSuccess, onError }) => {
  const keywordsArr = createKeywordsFormatForDb(market, country, keywords, groups);
  const requestData = {
    tagslug: tagSlug,
    keywords: keywordsArr,
    device: device,
  };
  postRequest({
    operation: tagsOperations.addKeywords,
    endpoint: endpoints.tags,
    data: requestData,
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const importKeywords = ({ values, tagSlug, onSuccess, onError }) => {
  const formDataObject = new FormData();
  formDataObject.append("file", values.file.originFileObj);
  formDataObject.append("tagslug", tagSlug);
  fileUploadRequest({
    endpoint: endpoints.tags,
    operation: tagsOperations.importKeywords,
    data: formDataObject,
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const importKeywordAutocompletes = ({ values, tagSlug, onSuccess, onError }) => {
  const formDataObject = new FormData();
  formDataObject.append("file", values.file.originFileObj);
  formDataObject.append("tagslug", tagSlug);
  fileUploadRequest({
    endpoint: endpoints.keyword,
    operation: keywordOperations.autocompleteimport,
    data: formDataObject,
    callback: (response) => {
      onSuccess(response);
    },
    errorCallback: (error) => {
      onError(error);
    },
  });
};

export const userRequestClient = _axios.create({
  baseURL: "https://dev1.applyzer.de",
});

const buildEndpoint = (endpoint, args = null, operation = null) => {
  const endpointArgs = args === null ? endpoint : sprintf(endpoint, args);
  return operation === null ? endpointArgs : `${endpointArgs}/${operation}`;
};

export const oldPostRequest = ({ operation = null, endpoint, args = null, data, callback, errorCallback = false, logging = false }) => {
  userRequestClient
    .post(buildEndpoint(endpoint, args, operation), data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
      if (logging) {
        console.error(error);
      }
    });
};

export const getPostData = (parameters, successfunction, errorfunction) => {
  if (!parameters) {
    return;
  }
  oldPostRequest({
    endpoint: "ajax",
    operation: "functions.php",
    data: parameters,
    callback: (responseData) => {
      successfunction(responseData);
    },
    errorCallback: (error) => {
      errorfunction(error);
    },
  });
};
