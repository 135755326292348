import { message, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { endpoints } from "../../library/api/endpoints";
import { postRequest } from "../../library/api/middleware";
import { generalOperations, tagsOperations } from "../../library/api/operations";
import { authenticateUser, getUserSubscription, logout, checkLogin } from "../../library/helpers/account-helper-functions";
import { getAppData } from "../../library/helpers/app-select-helpers";
import { getRouteItemByLink } from "../../library/helpers/session-helpers";
import { fetchTagList, setPlausiblePageView } from "../../library/helpers/tag-helper-functions";
import { TagContext } from "../../library/hooks/context/TagContext";
import { GlobalContext } from "../../library/hooks/context/GlobalContext";
import FeedbackModal from "../modal/FeedbackModal";
import LimitReachedModal from "../modal/LimitReachedModal";
import NavBar from "../NavBar/NavBar";
import socketIOClient from "socket.io-client";
import Cookies from "js-cookie";
//import Pricing from "../views/Account/Pricing/Pricing";
//import '../../assets/scss/styles.scss';

let lastlocationPath = "";

let socket = null;

export default function TagsDataLoader({ children }) {
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const [route, setRoute] = useState({});
  const [showNotifications, setShowNotifications] = useState(true);
  const [tokenChecked, setTokenChecked] = useState(0);

  const [globalDict, setGlobalDict] = useState({});
  const getGlobalValue = (key) => {
    return globalDict[key];
  };

  const setGlobalValue = (key, value) => {
    setGlobalDict((prevDict) => ({
      ...prevDict,
      [key]: value,
    }));
  };

  /**
   * Context functions
   */
  const setContextValue = (key, data) => {
    setTagsContextValues((tagsContextValues) => {
      let cloneValues = { ...tagsContextValues };
      cloneValues[key] = data;
      return cloneValues;
    });
  };

  const openNotification = (message, description, type = "info") => {
    //console.log("showNotifications", showNotifications);
    if (showNotifications) {
      if (type == "error") {
        api.warning({
          message: message,
          description: description,
          placement: "topRight",
        });
      } else {
        api.info({
          message: message,
          description: description,
          placement: "topRight",
        });
      }
    }
  };

  const setSelectedTag = (data) => {
    //console.log("setSelectedTag", data);
    if (!data) {
      return;
    }
    //setContextValue("appList", []);
    //setContextValue("allAppList", []);
    //setContextValue("selectedApp", {});
    //setContextValue("tagDataFetched", false);
    setContextValue("selectedTag", data);

    //setContextValue('selectedCountry', {});

    //

    localStorage.setItem("selectedTag", JSON.stringify(data));
  };

  const setSelectedApp = (data) => {
    //console.log("selectedApp", data);
    if (!data) {
      setContextValue("selectedApp", {});
      localStorage.setItem("selectedApp", JSON.stringify({}));
      return;
    }
    setPreviousSelectedApp(data);
    setContextValue("selectedApp", data);
    localStorage.setItem("selectedApp", JSON.stringify(data));
  };

  const setPreviousSelectedApp = (data) => {
    setContextValue("previousSelectedApp", data);
  };

  const setSelectedCompetitorApp = (data) => {
    setContextValue("selectedCompetitorApp", data);
  };

  const setSelectedCountry = (data) => {
    setContextValue("selectedCountry", data);
    localStorage.setItem("selectedCountry", JSON.stringify(data));
  };

  const setSelectedMarket = (data) => {
    setContextValue("selectedMarket", data);
  };

  const setSelectedDevice = (data) => {
    setContextValue("selectedDevice", data);
  };

  const setSelectedDataPeriod = (data) => {
    setContextValue("selectedDataPeriod", data);
  };

  const setModalopen = (data) => {
    setContextValue("modalopen", data);
  };

  const setSelectedDataType = (data) => {
    setContextValue("selectedDataType", data);
  };

  const setSelectedRankingType = (data) => {
    setContextValue("selectedRankingType", data);
  };

  const setTagList = (data) => {
    setContextValue("tagList", data);
  };

  const setAppList = (data) => {
    setContextValue("appList", data);
  };
  const setAllAppList = (data) => {
    setContextValue("allAppList", data);
  };

  const setAllTagsAppList = (data) => {
    setContextValue("allTagsAppList", data);
  };

  const setCompetitorAppList = (data) => {
    //console.log(data);
    setContextValue("competitorAppList", data);
  };

  const setCountryList = (data) => {
    setContextValue("countryList", data);
  };

  const setLanguageList = (data) => {
    setContextValue("languageList", data);
  };

  const setMarketList = (data) => {
    setContextValue("marketList", data);
  };

  const setDeviceList = (data) => {
    setContextValue("deviceList", data);
  };

  const setDataPeriods = (data) => {
    setContextValue("dataPeriods", data);
  };

  const setDataTypes = (data) => {
    setContextValue("dataTypes", data);
  };

  const setRankingTypes = (data) => {
    setContextValue("rankingTypes", data);
  };

  const setTagKeywordData = (data) => {
    setContextValue("tagKeywordData", data);
  };

  const setFetchedHtml = (data) => {
    setContextValue("fetchedHtml", data);
  };

  const setUserSubscriptions = (data) => {
    setContextValue("userSubscriptions", data);
  };

  const setValidSubscription = (data) => {
    setContextValue("validSubscription", data);
  };

  const setUserData = (data) => {
    setContextValue("userData", data);
  };

  const setUserStats = (data) => {
    setContextValue("userStats", data);
  };

  const setTriggerAction = (data) => {
    setContextValue("triggerAction", data);
  };

  const setLoggedin = (data) => {
    setContextValue("loggedin", data);
  };

  const setSelectedTab = (data) => {
    setContextValue("selectedTab", data);
  };

  /**
   * Context State
   */
  const [tagsContextValues, setTagsContextValues] = useState({
    //Selected Data Variables
    selectedTag: {},
    selectedApp: {},
    previousSelectedApp: {},
    selectedCompetitorApp: {},
    selectedTab: {},
    selectedCountry: {},
    selectedMarket: {},
    selectedDevice: {},
    selectedDataPeriod: {},
    selectedDataType: {},
    selectedRankingType: {},
    userSubscriptions: [],
    userStats: {},
    userData: {},
    validSubscription: false,
    loggedin: false,

    //Grouped Variables
    tagList: [],
    appList: [],
    competitorAppList: [],
    countryList: [],
    languageList: [],
    marketList: [],
    deviceList: [],
    dataPeriods: [],
    dataTypes: [],
    rankingTypes: [],
    tagKeywordData: [],
    allTagsAppList: null,

    //Actions and Conditionals
    generalConfigsFetched: false,
    tagListFetched: false,
    tagDataFetched: false,
    marketListFetched: false,
    countryListFetched: false,
    subscriptionFetched: false,
    modalopen: false,
    //Raw Html from old repo
    fetchedHtml: "",

    //Functions
    setModalopen: setModalopen,
    openNotification: openNotification,
    setContextValue: setContextValue,
    setSelectedTag: setSelectedTag,
    setSelectedApp: setSelectedApp,
    setPreviousSelectedApp: setPreviousSelectedApp,
    setSelectedCompetitorApp: setSelectedCompetitorApp,
    setSelectedCountry: setSelectedCountry,
    setSelectedMarket: setSelectedMarket,
    setSelectedDevice: setSelectedDevice,
    setSelectedDataPeriod: setSelectedDataPeriod,
    setSelectedDataType: setSelectedDataType,
    setSelectedRankingType: setSelectedRankingType,
    setTagList: setTagList,
    setAppList: setAppList,
    setAllAppList: setAllAppList,
    setAllTagsAppList: setAllTagsAppList,
    setCompetitorAppList: setCompetitorAppList,
    setCountryList: setCountryList,
    setLanguageList: setLanguageList,
    setMarketList: setMarketList,
    setDeviceList: setDeviceList,
    setDataPeriods: setDataPeriods,
    setDataTypes: setDataTypes,
    setRankingTypes: setRankingTypes,
    setTagKeywordData: setTagKeywordData,
    setFetchedHtml: setFetchedHtml,
    setUserSubscriptions: setUserSubscriptions,
    setUserStats: setUserStats,
    setUserData: setUserData,
    setValidSubscription: setValidSubscription,
    setTriggerAction: setTriggerAction,
    setLoggedin: setLoggedin,
    setSelectedTab: setSelectedTab,
  });

  useEffect(() => {
    //console.log("tagsContextValues?.selectedTag, tagsContextValues?.selectedApp, location.pathname", tagsContextValues?.selectedTag, tagsContextValues?.selectedApp, location.pathname)
    if (!checkLogin()) {
      return;
    }

    const tagSlug = tagsContextValues?.selectedTag?.slug || "";
    const appId = tagsContextValues?.selectedApp?.appId || "";
    let params = {};
    if (tagSlug) {
      params.tag_slug = tagSlug;
    }
    if (appId) {
      params.app_id = appId;
    }
    //console.log("params", params);
    const routeObj = getRouteItemByLink(routes, location.pathname, params);
    if (routeObj) {
      setRoute(routeObj);
    }

    if (Date.now() - tokenChecked < 300000) {
      return;
    }
    checkUserSubscription();
    authenticateUser({
      onSuccess: () => {
        setTokenChecked(Date.now());
      },
      onError: (error) => {
        message.error(error);
        //console.log("error")
        logout({
          tagContext: tagsContextValues,
          onSuccess: () => {
            navigate("login");
          },
        });
      },
    });
  }, [tagsContextValues?.selectedTag, tagsContextValues?.selectedApp, location.pathname]);

  useEffect(() => {
    setShowNotifications(tagsContextValues?.modalopen);
  }, [tagsContextValues?.modalopen]);

  useEffect(() => {
    //console.log("location", location);
    if (location.pathname != lastlocationPath) {
      lastlocationPath = location.pathname;
      // eslint-disable-next-line no-undef
      setPlausiblePageView();

      if (socket) {
        socket.emit("view", { view: location.pathname });
      }
    }
  }, [location.pathname]);

  function checkUserSubscription() {
    if (tagsContextValues.validSubscription) {
      return;
    }

    getUserSubscription({
      onSuccess: (response) => {
        //console.log("subscription", response.subscriptions);
        setUserSubscriptions(response.subscriptions);
        setUserStats(response.stats);
        setUserData(response.user);
        setContextValue("subscriptionFetched", true);
        if (!response.subscriptions.length) {
          setValidSubscription(false);
          //navigate("/pricing");
        } else {
          setValidSubscription(true);
        }
      },
      onError: (error) => {
        console.warn(error);
        //navigate("/pricing");
      },
    });
  }

  useEffect(() => {
    if (!checkLogin()) {
      return;
    }
    if (tagsContextValues.subscriptionFetched) {
      return;
    }
    checkUserSubscription();
  }, [tagsContextValues?.selectedTag, tagsContextValues?.selectedApp, location.pathname]);
  /*
	useEffect(() => {
		if (!checkLogin()) {
			return;
		}

		socket = socketIOClient.connect(process.env.REACT_APP_MESSAGES_API_URL, {
			query: { token: Cookies.get("login_jwt") },
		});
		socket.on("connect", () => {
			socket.emit("view", { view: location.pathname });
		});
	}, []);
  */

  useEffect(() => {}, [tagsContextValues?.modalopen]);

  useEffect(() => {
    if (location.pathname != lastlocationPath) {
      lastlocationPath = location.pathname;
      // eslint-disable-next-line no-undef
      setPlausiblePageView();

      if (socket) {
        socket.emit("view", { view: location.pathname });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!checkLogin()) {
      return;
    }

    checkUserSubscription();

    socket = socketIOClient.connect(process.env.REACT_APP_MESSAGES_API_URL, {
      query: { token: Cookies.get("login_jwt") },
    });
    socket.on("connect", () => {
      socket.emit("view", { view: location.pathname });
    });

    socket.on("trigger", (data) => {
      //setTriggerAction(data.content);

      setTriggerAction(data);
    });

    socket.on("message", (data) => {
      openNotification("Data ready!", data.content, "info");
      //console.log("message date", data.content);
      //setTrigger(trigger.datagridrefresh=true)
    });
  }, [tagsContextValues.loggedin]);

  useEffect(() => {
    if (tagsContextValues.tagListFetched || !checkLogin()) {
      return;
    }

    fetchTagList({
      onSuccess: (response) => {
        setTagList(response);
        setContextValue("tagListFetched", true);
        setContextValue("tagDataFetched", false);
      },
    });
  }, [tagsContextValues.tagListFetched]);

  useEffect(() => {
    //console.log("location?.pathname", location?.pathname);
    if (!location?.pathname.includes("/verify") && !location?.pathname.includes("/reset") && !location?.pathname.includes("/login") && !location?.pathname.includes("/registration") && !location?.pathname.includes("/signupconfirm") && !location?.pathname.includes("/account")) {
      if (!checkLogin()) {
        setValidSubscription(false);
        navigate("/login");
        return;
      }
      if (!tagsContextValues.userSubscriptions && checkLogin) {
        setValidSubscription(false);
        //navigate("/pricing");
        return;
      }
      if (tagsContextValues.userSubscriptions.length) {
        setValidSubscription(true);
      } else {
        setValidSubscription(false);
        //navigate("/pricing");
      }
    }
  }, [tagsContextValues.userSubscriptions, location.pathname]);

  useEffect(() => {
    //tagsContextValues.tagDataFetched ||

    //console.log("tagsContextValues.selectedTab", tagsContextValues.selectedTab);
    if (!tagsContextValues?.selectedTag?.slug) {
      return;
    }
    postRequest({
      operation: tagsOperations.detail,
      endpoint: endpoints.tags,
      data: { tagslug: tagsContextValues.selectedTag.slug },
      callback: ({ message = null, response = {} } = {}) => {
        if (message !== "OK") {
          console.warn("Unable to fetch tag details");
          setContextValue("tagDataFetched", true);
          return;
        }

        //setAllAppList([...getAppData(response?.apps?.myapps, response?.apps?.metadata), ...getAppData(response?.apps?.competitorapps, response?.apps?.metadata)])
        setAppList(getAppData(response?.apps?.myapps, response?.apps?.metadata));

        setCompetitorAppList(getAppData(response?.apps?.competitorapps, response?.apps?.metadata));

        setAllAppList([...getAppData(response?.apps?.myapps, response?.apps?.metadata), ...getAppData(response?.apps?.competitorapps, response?.apps?.metadata)]);

        setContextValue("tagDataFetched", true);
        setTagKeywordData(response?.keywords);
      },
      errorCallback: (error) => {
        console.warn(error);
      },
    });
  }, [tagsContextValues?.selectedTag, tagsContextValues?.selectedTab]);

  useEffect(() => {
    if (tagsContextValues.appList?.length || tagsContextValues.competitorAppList?.length) {
      setAllAppList([...tagsContextValues.appList, ...tagsContextValues.competitorAppList]);
    }
  }, [tagsContextValues.appList, tagsContextValues.competitorAppList]);

  useEffect(() => {
    if (tagsContextValues.marketListFetched) {
      return;
    }
    postRequest({
      operation: generalOperations.markets,
      endpoint: endpoints.general,
      data: {},
      callback: ({ message = null, response = {} } = {}) => {
        if (message !== "OK") {
          console.warn("Unable to fetch init config");
          return;
        }
        setMarketList(response);
        setContextValue("marketListFetched", true);
      },
      errorCallback: (error) => {
        console.warn(error);
      },
    });
  }, [tagsContextValues.marketListFetched]);

  useEffect(() => {
    if (tagsContextValues.generalConfigsFetched) {
      return;
    }
    postRequest({
      operation: generalOperations.initConfig,
      endpoint: endpoints.general,
      data: {},
      callback: ({ message = null, response = {} } = {}) => {
        if (message !== "OK") {
          console.warn("Unable to fetch init config");
          return;
        }
        setDataPeriods(response?.dataPeriods);
        setDataTypes(response?.dataTypes);
        setDeviceList(response?.devices);
        setRankingTypes(response?.rankingTypes);
        setLanguageList(response?.languageList);
        setContextValue("generalConfigsFetched", true);
      },
      errorCallback: (error) => {
        console.warn(error);
      },
    });
  }, [tagsContextValues.generalConfigsFetched]);

  useEffect(() => {
    if (!checkLogin() || !tagsContextValues.tagListFetched) {
      return;
    }
    if (!tagsContextValues.tagList.length) {
      return setSelectedTag({});
    }
    const tagSlugs = tagsContextValues.tagList.map((tag) => tag.slug);
    if (tagsContextValues?.selectedTag?.slug && tagSlugs.includes(tagsContextValues?.selectedTag?.slug)) {
      return;
    }
    const storedTag = localStorage.getItem("selectedTag") && JSON.parse(localStorage.getItem("selectedTag"));
    if (tagSlugs.length && tagSlugs.includes(storedTag?.slug)) {
      return setSelectedTag(storedTag);
    }
    setSelectedTag(tagsContextValues.tagList[0]);
  }, [tagsContextValues?.tagList]);

  useEffect(() => {
    if (!tagsContextValues?.marketList?.length || tagsContextValues?.selectedMarket?.id) {
      return;
    }
    setSelectedMarket(tagsContextValues?.marketList[0]);
  }, [tagsContextValues?.marketList]);

  useEffect(() => {
    if (tagsContextValues.countryListFetched || !tagsContextValues?.selectedMarket?.id) {
      return;
    }
    postRequest({
      operation: generalOperations.countries,
      endpoint: endpoints.general,
      data: { marketid: tagsContextValues.selectedMarket.id },
      callback: (responseData) => {
        setCountryList(responseData.response);
        setContextValue("countryListFetched", true);
      },
      errorCallback: (error) => {
        console.warn(error);
      },
    });
  }, [tagsContextValues.selectedMarket]);

  useEffect(() => {
    if (!checkLogin() || tagsContextValues.subscriptionFetched) {
      return;
    }

    checkUserSubscription();
  }, [tagsContextValues.subscriptionFetched]);

  useEffect(() => {
    if (!checkLogin()) {
      if (location?.pathname == "/") {
        return navigate("/login");
      }
    } else {
      if (location?.pathname.includes("/verify") || location?.pathname.includes("/reset") || location?.pathname.includes("/login") || location?.pathname.includes("/registration")) {
        return navigate("/");
      }
      return () => {
        //socket.disconnect();
      };
    }
  }, []);
  //
  return (
    <TagContext.Provider value={tagsContextValues}>
      {contextHolder}
      <GlobalContext.Provider value={{ globalDict, getGlobalValue, setGlobalValue }}>
        {route?.includeNav && <NavBar route={route} />}

        {children}

        <FeedbackModal />
        <LimitReachedModal />
      </GlobalContext.Provider>
    </TagContext.Provider>
  );
}
